.amount__circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-style: solid;
  border-radius: 50%;
  transition: background 0.2s ease-in;

  &.size70 {
    width: 70px;
    height: 70px;
    border-width: 3px;

    .amount__circle__value {
      font-size: 18px;
    }
  }

  &.size180 {
    width: 180px;
    height: 180px;
    border-width: 6px;

    .amount__circle__value {
      font-size: 45px;
    }
  }
}

@media (max-width: 320px) {
  .amount__circle {

    &.size70 {
      width: 55px;
      height: 55px;

      .amount__circle__value {
        font-size: 16px;
      }
    }
  }
}
